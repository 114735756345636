@font-face {
	font-family: 'Myriad Pro';
	src: url('../fonts/MyriadPro-Regular.eot');
	src: local('Myriad Pro Regular'), local('MyriadPro-Regular'),
		url('../fonts/MyriadPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/MyriadPro-Regular.woff') format('woff'),
		url('../fonts/MyriadPro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.eot');
    src: local('Roboto Bold'), local('Roboto-Bold'),
        url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Bold.woff') format('woff'),
        url('../fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.eot');
    src: local('Roboto'), local('Roboto-Regular'),
        url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Regular.woff') format('woff'),
        url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.eot');
    src: local('Roboto Black'), local('Roboto-Black'),
        url('../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Roboto-Black.woff') format('woff'),
        url('../fonts/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}