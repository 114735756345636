@import "partials/reset.css";
@import "partials/fonts.css";

/* my style */

@import "partials/funcs";

* {
    box-sizing: border-box;
}
html, body {
    height: 100%;
}
body {
    background-color: #f6fde4;
    color: #1e1e1e;
    display: flex;
    flex-direction: column;
}
.no-scroll {
    height: 100%;
    width: 100%;
    position: fixed;
    overflow: hidden;
}

.container {
    width: 1140px;
    margin: 0 auto;
    &_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    @include laptop {
        width: 780px;
    }
    @include tablet {
        width: 550px;
    }
    @include mobile {
        width: 320px;
    }
}

.header {
    @include mobile {
        padding: 15px 0;
    }
    &__logo {
        width: 150px;
        height: 60px;
        img {
            max-height: 100%;
        }
        @include mobile {
            width: 107px;
            height: 33px;
        }
    }
    &__site-menu {
        width: 602px;
        @include tablet {
            display: none;
        }
        &_mobile {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            animation: leftIn 0.5s forwards;
            background: linear-gradient(to right, rgba(#222, .6) 0 70%, #408a2d 70% 100%);
            height: 100%;
            width: 100%;
            padding: 25px;
            text-align: right;
            z-index: 1000;
            @include mobile {
                background: linear-gradient(to right, rgba(#222, .6) 0 45%, #408a2d 45% 100%);
                padding: 25px 14px;
            }
        }
    }
    &__contact {
        @include laptop {
            display: none;
        }
    }
}
@keyframes leftIn {
    from {
        transform: translateX(1000px);
    } to {
        transform: translateX(0)
    }
}
.mobile-contact-list {
    display: none;
    @include tablet {
        display: flex;
        width: 200px;
        justify-content: space-between;
        flex-shrink: 0;
        margin-left: auto;
    }
    @include mobile {
        width: 122px;
        margin-left: 20px;
    }
}
.mobile-contact {
    display: block;
    width: 22px;
    height: 22px;
    img {
        width: 100%;
    }
}
.mobile-menu-button-open {
    display: none;
    width: 20px;
    height: 16px;
    background: url('../img/icons/Menu_icon.png') center center no-repeat;
    border: none;
    flex-shrink: 0;
    padding: 0;
    &:hover {
        cursor: pointer;
    }
    @include tablet {
        display: block;
        margin-left: auto;
    }
}
.mobile-menu-button-close {
    display: none;
    width: 16px;
    height: 16px;
    position: relative;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-bottom: 45px;
    @include tablet {
        display: inline-block;
    }
    &::before, &::after {
        content: '';
        width: 22px;
        height: 2px;
        background-color: #f9feee;
        position: absolute;
        top: 50%;
        left: 0;
    }
    &::after {
        transform: rotate(45deg);
    }
    &::before {
        transform: rotate(-45deg);
    }
}
.menu-list {
    display: flex;
    justify-content: space-between;
    @include tablet {
        flex-direction: column;
        align-items: flex-end;
    }
    li {
        @include tablet {
            margin-bottom: 40px;
        }
    }
    &__link {
        text-decoration: none;
        font: 700 16px 'Roboto';
        color: #1e1e1e;
        transition: all 0.3s;
        display: inline-block;
        padding: 30px 0;
        border-top: 3px solid transparent;
        &:hover {
            color: #408a2d;
            border-top: 3px solid #408a2d;
            @include tablet {
                color: #f9feee;
            }
        }
        @include tablet {
            color: #f9feee;
            font-weight: normal;
            padding: 0;
        }
        &_active {
            color: #408a2d;
            border-top: 3px solid #408a2d;
            @include tablet {
                color: #f9feee;
                font-weight: 700;
            }
        }
    }
}
.contact-link {
    color: #1e1e1e;
    font: 700 16px 'Roboto';
    text-decoration: none;
    vertical-align: middle;
    &::before {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(../img/icons/Phone_icon.png) no-repeat;
        background-size: cover;
        vertical-align: middle;
        margin-right: 13px;
    }
    &:hover {
        color: #408a2d;
    }
}
.banner-area {
    position: relative;
    border-radius: 10px;
    height: 540px;
    overflow: hidden;
    @include mobile {
        height: 400px;
    }
}
.banner-video {
    object-fit: cover;
    height: 100%;
    width: 100%;
}
.banner {
    background-color: rgba(#222, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 47px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include mobile {
        padding-bottom: 22px;
    }
    &__slogan {
        background-color: rgba(#fff, .7);
        padding: 30px 65px;
        margin-bottom: 90px;
        line-height: 66px;
        @include tablet {
            padding: 25px 40px;
            line-height: 40px;
        }
        @include mobile {
            width: 270px;
            padding: 15px 20px;
            line-height: 30px;
        }
    }
    &__address {
        background-color: #fff;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        padding: 20px 30px;
        @include mobile {
            padding: 15px 25px;
        }
        address {
            font: 700 16px 'Roboto';
            color: #1e1e1e;
            vertical-align: middle;
            &::before {
                content: '';
                display: inline-block;
                height: 24px;
                width: 24px;
                background: url(../img/icons/Loation_icon.png) no-repeat;
                background-size: cover;
                vertical-align: middle;
                margin-right: 15px;
                @include mobile {
                    width: 22px;
                    height: 22px;
                }
            }
            @include mobile {
                font-size: 14px;
            }
        }
    }
}
.slogan-bold {
    font: 900 36px 'Roboto';
    color: #1e1e1e;
    @include tablet {
        font-size: 30px;
    }
    @include mobile {
        font-size: 22px;
    }
    &_green {
        color: #408a2d;
        text-transform: uppercase;
    }
}
.slogan-thin {
    display: block;
    font: 28px 'Roboto';
    color: #1e1e1e;
    text-transform: uppercase;
    @include tablet {
        font-size: 22px;
    }
    @include mobile {
        font-size: 14px;
    }
}
.trouble-section {
    padding: 40px 0;
    @include mobile {
        padding: 30px 0;
    }
}
.section-title {
    font: 900 30px 'Roboto';
    color: #1e1e1e;
    text-align: center;
    margin-bottom: 30px;
    @include tablet {
        font-size: 24px;
    }
    @include mobile {
        font-size: 18px;
    }
}
.trouble-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
    }
    li {
        width: 23%;
        margin-bottom: 30px;
        padding-right: 10px;
        @include laptop {
            width: 49%;
            padding: 0;
            margin-bottom: 20px;
        }
        @include mobile {
            width: 100%;
            margin-bottom: 15px;
            display: none;
        }
        &:nth-child(-n+4) {
            @include mobile {
                display: block;
            }
        }
        &.active {
            display: block;
        }
    }
}
.expand-mobile {
    display: none;
    @include mobile {
        display: block;
        text-align: right;
        text-decoration: underline;
        background-color: transparent;
        border: none;
        float: right;
    }
}
.trouble-link {
    display: flex;
    align-items: center;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.08);
    text-decoration: none;
    transition: all 0.3s;
    overflow: hidden;
    span {
        font: 700 18px 'Roboto';
        color: #1e1e1e;
        @include mobile {
            font-size: 16px;
        }
    }
    &__image {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 82px;
        height: 78px;
        margin-right: 15px;
        transition: all 0.3s;
        padding: 16px 18px;
        background-color: #f9feee;
        @include mobile {
            width: 77px;
            height: 66px;
            margin-right: 30px;
        }
    }
    img {
        max-width: 100%;
        max-height: 100%;
    }
    &:hover {
        background-color: #f9feee;
        box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.18);
        .trouble-link__image {
            background-color: #408a2d;
        }
    }
}
.services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include mobile {
        flex-direction: column;
    }
    li {
        width: 21%;
        margin-bottom: 30px;
        @include laptop {
            width: 23%;
        }
        @include tablet {
            width: 48%;
        }
        @include mobile {
            width: 100%;
            margin-bottom: 15px;
        }
    }
}
.service-link {
    height: 170px;
    background-color: #fff;
    display: block;
    text-align: center;
    box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.08);
    text-decoration: none;
    border-radius: 9px;
    padding: 30px 25px 20px;
    @include laptop {
        padding: 15px;
    }
    @include tablet {
        padding: 30px 25px 20px;
    }
    @include mobile {
        display: flex;
        align-items: center;
        height: auto;
        padding: 0;
        text-align: left;
        overflow: hidden;
    }
    &:hover {
        background-color: #f9feee;
        box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.18);
        @include mobile {
            .service-link__image {
                background-color: #408a2d;
            }
        }
    }
    &__image {
        width: 60px;
        height: 60px;
        margin: auto;
        margin-bottom: 20px;;
        img {
            max-width: 100%;
            max-height: 100%;
        }
        @include mobile {
            width: 77px;
            height: 66px;
            margin: 0;
            margin-right: 30px;
            padding: 16px 18px;
            background-color: #f9feee;
        }
    }
    span {
        font: 700 18px 'Roboto';
        color: #1e1e1e;
    }
}
.specialists-section {
    background: linear-gradient(#f6fde4 0 50%, #408a2d 50% 100%);
    padding-bottom: 60px;
    position: relative;
    &::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background-color: rgba(#fff, 0.03);
        position: absolute;
        bottom: 0;
    }
    @include mobile {
        padding: 15px 0 30px;
    }
}
.slider {
    position: relative;
    width: 100%;
}
.slider-button-next, .slider-button-prev {
    width: 8px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    z-index: 100;
    background: url('../img/icons/Arrow_right.png') no-repeat;
    background-size: 8px 14px;
}
.slider-button-prev {   
    left: 10px;
    top:40%;
    transform: scaleX(-1);
    &:hover {
        transform: none;
        background: url('../img/icons/Arrow_left.png') no-repeat;
    }
    @include mobile {
        left: 17px;
    }
}
.slider-button-next {
    background: url('../img/icons/Arrow_right.png') no-repeat;
    background-size: 8px 14px;
    right: 10px;
    top:40%;
    &:hover {
        transform: scaleX(-1);
        background: url('../img/icons/Arrow_left.png') no-repeat;
    }
    @include mobile {
        right: 17px;
    }
}
.slick-track {
    display: flex;
    align-items: center;
}
.doctor-card {
    display: flex;
    flex-direction: column;
    box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.18);
    background: #fff;
    border-radius: 10px;
    width: 195px;
    height: 244px;
    margin: auto;
    padding: 26px;
    text-decoration: none;
    @include mobile {
        width: 244px;
        height: 335px;
        margin: auto;
        padding: 0;
    }
    &__photo {
        width: 127px;
        height: 131px;
        flex-shrink: 0;
        margin: auto;
        margin-bottom: 23px;
        border-radius: 10px;
        overflow: hidden;
        @include mobile {
            width: 100%;
            height: 146px;
            margin: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &__name {
        color: #408a2d;
        font: 700 16px 'Roboto';
        text-align: center;
        @include mobile {
            text-align: center;
        }
    }
    &__desc {
        display: none;
    }
    &__text {
        @include mobile {
            padding: 15px;
            text-align: center;
            line-height: 20px;
        }
    }
}
// .slick-current .doctor-card{
//     opacity: 0;
// }
.doctor-card_right {
    margin-right: 68px;
    @include mobile {
        margin-right: 0;
    }
}
.doctor-card_left {
    margin-left: 68px;
    @include mobile {
        margin-left: 0;
    }
}
.slick-current {
    .doctor-card {
        margin-left: -86px;
        flex-direction: row;
        width: 555px;
        height: 300px;
        padding: 46px 20px 46px 67px;
        @include laptop {
            margin: auto;
        }
        @include tablet {
            width: 480px;
            height: auto;
            padding: 20px;
            margin: auto;
        }
        @include mobile {
            width: 244px;
            height: 335px;
            flex-direction: column;
            margin: auto;
            padding: 0;
        }
        &__photo {
            width: 195px;
            height: 217px;
            margin-right: 30px;
            flex-shrink: 0;
            @include tablet {
                margin: auto;
                margin-right: 30px;
            }
            @include mobile {
                width: 100%;
                height: 146px;
                margin: 0;
            }
        }
        &__name {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 900;
            text-align: left;
            @include mobile {
                font-size: 16px;
                text-align: center;
                margin-bottom: 15px;
            }
        }
        &__desc {
            display: block;
            color: #1e1e1e;
            font: 16px 'Roboto';
            line-height: 22px;
            height: 155px;
            overflow: hidden;
            @include mobile {
                font-size: 14px;
                line-height: 20px;
                height: 100px;
            }
        }
        &__text {
            @include mobile {
                padding: 15px;
                text-align: center;
                line-height: 20px;
            }
        }
    }
}

.footer-site {
    background-color: #408a2d;
    padding: 40px 0;
    margin-top: auto;
    @include tablet {
        padding: 20px 0;
    }
}
.footer-main-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 55px;
    @include tablet {
        align-items: center;
        margin-bottom: 30px;
    }
    @include mobile {
        display: none;
    }
}
.footer-logo {
    width:150px;
    height: 46px;
    @include laptop {
        width: 120px;
    }
    img {
        max-width: 100%;
    }
}
.footer-nav {
    height: 120px;
    width: 265px;
    @include laptop {
        width: 190px;
    }
    @include tablet {
        display: none;
    }
}
.footer-menu-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    li {
        margin-bottom: 20px;
    }
}
.footer-link {
    font: 16px 'Roboto';
    color: #fff;
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
        color: #aecb1e;
    }
    @include laptop {
        font-size: 14px;
    }
    img {
        margin-right: 14px;
        @include laptop {
            margin-right: 10px;
        }
    }
}
.footer-contacts {
    display: flex;
    justify-content: space-between;
}
.socials-links {
    margin-right: 70px;
    @include laptop {
        margin-right: 20px;
    }
    @include tablet {
        display: none;
    }
}
.socials-links li {
    margin-bottom: 20px;
}
.contact-links li {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
    address {
        font: 16px 'Roboto';
        color: #fff;
        @include laptop {
            font-size: 14px;
        }
        img {
            margin-right: 14px;
            @include laptop {
                margin-right: 10px;
            }
        }
    }
}
.footer-copy-block {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
.copyright {
    font: 12px 'Roboto';
    color: #fff;
    @include mobile {
        font-size: 10px;
        width: 170px;
    }
}
.designed-block {
    font: 12px 'Roboto';
    color: #fff;
    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-size: 10px;
    }
}
.link-to-design {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 25px;
    background: url('../img/icons/Logo_GM.png') no-repeat;
    background-size: contain;
    @include mobile {
        width: 40px;
        height: 20px;
    }
}
.breadcrumb {
    background-color: #d1efb7;
    padding: 15px 0;
    font: 14px 'Roboto';
    color: #1e1e1e;
    &__link {
        color: #408a2d;
        text-decoration: none;
    }
    @include mobile {
        font-size: 10px;
    }
}
.disease-section {
    padding: 45px 0;
    @include mobile {
        padding: 25px 0;
    }
}

@import './about.scss';
@import './specialists.scss';
@import './servises.scss';
@import './reviews.scss';
@import './contact.scss';