.contact-section {
    padding-top: 45px;
    @include mobile {
        padding-top: 30px;
    }
}
.contact-map {
    height: 425px;
    position: relative;
    #map {
        height: 100%;
    }
    .container {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
.mobile-none {
    @include tablet {
        display: none;
    }
}
.mobile-contact-button {
    display: none;
    border: none;
    color: #fff;
    font: 14px 'Roboto';
    text-transform: uppercase;
    background-color: #408a2d;
    border-radius: 7px;
    padding: 17px 50px;
    margin-bottom: 30px;
    @include tablet {
        display: block;
    }
}
.contact-form {
    position: absolute;
    left: calc((100% - 1140px) / 2);
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 10px;
    z-index: 1;
    padding: 36px 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 3px 4px 5px 1px rgba(44, 63, 1, 0.03);
    @include laptop {
        padding: 25px;
        left: calc((100% - 780px) / 2);
    }
    @include tablet {
        display: none;
    }
    &.active {
        @include tablet {
            display: flex;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 20px;
            width: 95%;
        }
    }
}
.form-title {
    font: 700 24px 'Roboto';
    text-align: center;
    margin-bottom: 40px;
}
.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    @include tablet {
        width: 100%;
        flex-direction: column;
        margin-bottom: 0;
    }
}
.inputBlock {
    width: 48%;
    @include tablet {
        width: 100%;
        margin-bottom: 20px;
    }
}
.label-text {
    display: block;
    font: 12px 'Roboto';
    margin-bottom: 8px;
    margin-left: 14px;
}
.text-input {
    border: 1px solid #dbdbda;
    border-radius: 10px;
    padding: 15px;
    font: 18px 'Roboto';
    &:focus {
        border: 1px solid #408a2d;
    }
    @include tablet {
        width: 100%;
        padding: 10px;
    }
    &_area {
        width: 100%;
        resize: none;
    }
}
.contact-text-area {
    width: 100%;
    margin-bottom: 20px;
}
.submit-button {
    background-color: #408a2d;
    font: 700 14px 'Roboto';
    text-transform: uppercase;
    color: #fff;
    border: none;
    border-radius: 10px;
    display: inline-block;
    margin: 0 auto;
    padding: 15px 60px;
}
.form-close-button {
    display: none;
    @include tablet {
        display: block;
    }
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-bottom: 45px;
    &::before, &::after {
        content: '';
        width: 22px;
        height: 2px;
        background-color: #1e1e1e;
        position: absolute;
        top: 50%;
        left: 0;
    }
    &::after {
        transform: rotate(45deg);
    }
    &::before {
        transform: rotate(-45deg);
    }
}
.contact-page-links {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    font: 16px 'Roboto';
    @include laptop {
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-right: -15px;
    }
    @include tablet {
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        margin-right: 0;
    }
    li {
        @include laptop {
            margin-bottom: 15px;
            margin-right: 15px;
            width: 30%;
        }
        @include tablet {
            margin-right: 0;
            width: auto;
        }
    }
    span, img {
        vertical-align: middle;
    }
}
.contact-page-link {
    color: #1e1e1e;
    text-decoration: none;
}
// @media screen and (max-width: 1680px) {
//     .contact-form {
//         left: 230px;
//     }
// }
// @media screen and (max-width: 1366px) {
//     .contact-form {
//         left: 110px;
//     }
// }
// @media screen and (max-width: 1200px) {
//     .contact-form {
//         left: 90px;
//     }
// }
// @media screen and (max-width: 992px) {
//     .contact-form {
//         left: 80px;
//     }
// }
// @media screen and (max-width: 768px) {
//     .contact-form {
//         left: 230px;
//     }
// }
// @media screen and (max-width: 480px) {
//     .contact-form {
//         left: 230px;
//     }
// }