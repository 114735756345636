@import "globals";

@function rem($px) {
  @return #{$px/$rootFontSize}rem;
}
@mixin laptop {
  @media screen and (max-width: 1150px) {
      @content;
  }
}
@mixin tablet {
  @media screen and (max-width: 800px) {
      @content;
  }
}
@mixin mobile {
  @media screen and (max-width: 560px) { //480px
      @content;
  }
}
