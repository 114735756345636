.review-section {
    padding: 40px 0;
}
.review-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .slider-button-prev {
        left: -15px;
    }
    .slider-button-next {
        right: -15px;
    }
    .slider-button-next, .slider-button-prev {
        @include mobile {
            top: 27%;
        }
    }
}
.review-item {
    margin-bottom: 30px;
    width: 48%;
    @include tablet {
        width: 100%;
    }
    &__video {
        height: 290px;
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 20px;
        @include mobile {
            height: 150px;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    &__name {
        font: 700 18px 'Roboto';
        @include mobile {
            font-size: 14px;
        }
    }
    &__diseas {
        font: 16px 'Roboto';
        @include mobile {
            font-size: 14px;
        }
        strong {
            font-weight: 700;
        }
    }
    &__service {
        font: 16px 'Roboto';
        @include mobile {
            font-size: 14px;
        }
        strong {
            font-weight: 700;
        }
    }
}