.expert-section {
    padding: 45px 0;
    @include mobile {
        padding: 30px 0;
    }
}
.specialist-list li {
    @include mobile {
        padding-bottom: 16px;    
    }
}
.specialist-card {
    border-radius: 10px;
    display: flex;
    transition: all 0.5s;
    padding: 15px;
    color: #1e1e1e;
    margin: auto;
    &:hover {
        background-color: #fff;
        box-shadow: 5px 9px 18px 2px rgba(44, 63, 1, 0.08);
    }
    @include mobile {
        flex-direction: column;
        background-color: #fff;
        box-shadow: 4px 7px 8px 1px rgba(44, 63, 1, 0.1);
        width: 244px;
        padding: 0;
        height: 450px;
    }
    &__photo {
        width: 195px;
        height: 200px;
        margin-right: 55px;
        border-radius: 10px;
        overflow: hidden;
        flex-shrink: 0;
        @include tablet {
            margin-right: 30px;
        }
        @include mobile {
            width: 100%;
            height: 145px;
            margin: 0 0 20px 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__desc {
        @include mobile {
            padding: 0 40px 0 15px;
        }
    }
    &__name {
        font: 900 18px 'Roboto';
        color: #408a2d;
        margin-bottom: 20px;
        @include tablet {
            font-size: 16px;
        }
    }
    &__title {
        font: 700 16px 'Roboto';
        margin-bottom: 20px;
        @include tablet {
            font-size: 14px;
        }
    }
    &__specialization {
        font: 16px 'Roboto';
        margin-bottom: 20px;
        @include tablet {
            font-size: 14px;
        }
        strong {
            font-weight: 700;
        }
    }
    &__exp {
        font: 16px 'Roboto';
        margin-bottom: 20px;
        @include tablet {
            font-size: 14px;
        }
        strong {
            font-weight: 700;
        }
    }
    &__amount {
        font: 16px 'Roboto';
        margin-bottom: 20px;
        @include tablet {
            font-size: 14px;
        }
        strong {
            font-weight: 700;
        }
    }
}