.about-clinic-section {
    padding: 45px 0;
    @include mobile {
        padding: 30px 0;
    }
    .container {
        display: flex;
        flex-direction: column;
    }
}
.about-text-area {
    font: 16px 'Roboto';
    color: #1e1e1e;
    line-height: 22px;
    p {
        margin-bottom: 30px;
        @include mobile {
            margin-bottom: 20px;
        }
    }
    @include tablet {
        font-size: 14px;
        height: 240px;
        overflow: hidden;
        margin-bottom: 20px;
        order: 1;
    }
    &.mobile-active {
        @include tablet {
            height: auto;
            overflow: visible;
        }
    }
}
.about-mobile-expand-link {
    display: none;
    @include tablet {
        display: inline-block;
        color: #408a2d;
        font: 500 14px 'Roboto';
        order: 1;
    }
}

.image-slide {
    width: 195px;
    height: 145px;
    border-radius: 10px;
    overflow: hidden;
    margin: auto;
    transition: all 0.2s;
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @include mobile {
        width: 214px;
    }
}
.image-slider-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    @include tablet {
        margin-bottom: 30px;
    }
    .slider-button-next, .slider-button-prev  {
        top: 50%;
    }
    .slider-button-next {
        @include mobile {
            right: 35px;
        }
    }
    .slider-button-prev {
        @include mobile {
            left: 35px;
        }
    }
    .slick-track {
        height: 310px;
        @include mobile {
            height: auto;
        }
    }
    .slide {
        display: flex;
    }
    .slick-current .image-slide {
        // width: 555px;
        // height: 310px;
        transform: scale(2.5);
        @include laptop {
            transform: scale(1.6);
        }
        @include mobile {
            transform: none;
        }
    }
}