.service-info-section {
    padding-bottom: 45px;
    display: none;
    transform: scale(0);
    transition: all 0.5s;
    @include mobile {
        padding-bottom: 30px;
    }
    &.active {
        display: block;
        transform: scale(1);
    }
    .section-title {
        @include mobile {
            margin-bottom: 10px;
        }
    }
    .image-slider-container {
        @include tablet {
            margin-bottom: 0;
        }
    }
}
.services-page-section {
    padding-top: 45px;
    @include tablet {
        padding: 25px 0;
    }
}
.section-subtitle {
    font: 700 18px 'Roboto';
    color: #1e1e1e;
    text-align: center;
    margin-bottom: 45px;
    @include laptop {
        margin-bottom: 0;
    }
    @include mobile {
        font-size: 14px;
        margin-bottom: 30px;
    }
}
.diagnostic-text {
    margin-top: 45px;
    font: 16px 'Roboto';
    @include laptop {
        margin-top: 0;
    }
    @include mobile {
        margin-top: 20px;
    }
    ul, ol {
        margin: 1em 0;
        padding-left: 40px;
    }
    ul {
        list-style-type: disc;
    }
    ol {
        list-style-type: decimal;
    }
}
.equipment-section {
    padding-bottom: 45px;
    @include mobile {
        padding-bottom: 30px;
    }
}
.equipment-slider {
    .slick-arrow {
        @include tablet {
            top: 76px;
        }
    }
    .slick-track {
        @include mobile {
            display: block;
        }
    }
}
.equipment-slide {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    @include tablet {
        flex-direction: column;
        padding: 0;
    }
    &__desc {
        width: 530px;
        @include laptop {
            width: 50%;
        }
        @include tablet {
            width: 100%;
            padding: 25px 20px;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 4px 7px 8px 1px rgba(44, 63, 1, 0.1);
        }
    }
    &__title {
        font: 700 18px 'Roboto';
        margin-bottom: 30px;
        @include tablet {
            font-size: 16px;
        }
    }
    &__text {
        font: 16px 'Roboto';
        @include tablet {
            font-size: 14px;
        }
    }
    &__photo {
        width: 395px;
        // height: 265px;
        border-radius: 10px;
        overflow: hidden;
        img {
            width: 100%;
            object-fit: contain;
        }
        @include laptop {
            width: 45%;
        }
        @include tablet {
            order: -1;
            width: 80%;
            margin-bottom: 20px;
        }
    }
}